import React, { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { default as ProductErrorIcon } from "@material-ui/icons/NewReleases";
import { default as LowMarginIcon } from "@material-ui/icons/MonetizationOn";
import { TableRow, TableCell, Collapse } from "@material-ui/core";
import DebouncedCurrencyInputV2 from "../FormFields/DebouncedCurrencyInputV2";
import ProductsExpansionSection from "./ProductsExpansionSection";
import { Field } from "formik";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { DebouncedTextField } from "../FormFields/DebouncedTextField";
import { isNil, has } from "ramda";
import { pipe } from "fp-ts/lib/function";
import { serviceRowStyles } from "../../material-ui-styles";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import { ProductSelectionOption } from "./ProductRow";
import { PossibleEjiService } from "../../generated/nest-graphql";

type ServiceTableRowProps = {
  row: any;
  serviceIdx: number;
  partsStores: any;
  ejiType?: string;
  taxable: boolean;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
  serviceList: PossibleEjiService[];
  allServiceWithErrors?: boolean;
};

const ServiceRow = ({
  row,
  serviceIdx,
  partsStores,
  taxable,
  ejiType,
  calculatePossibleEJIPriceInfo,
  parts,
  serviceList,
  allServiceWithErrors,
}: ServiceTableRowProps) => {
  const classes = serviceRowStyles();
  const [expanded, setExpanded] = useState(false);
  return (
    <Field name={`services[${serviceIdx}]`}>
      {({ field: { value }, meta: { error }, form: { values } }) => {
        let customerCell = (
          <TableCell style={{ fontSize: "1rem" }}>{isNil(row.customerPrice) ? "-- --" : row.customerPrice}</TableCell>
        );
        if (allServiceWithErrors) {
          customerCell = null;
        } else if (has("items", error)) {
          customerCell = <TableCell></TableCell>;
        }

        const technicianPay = value.technicianPay ? Number(value.technicianPay) : 0;
        const vendorPartsCost = value.partsCalcs?.vendorCost ? Number(value.partsCalcs.vendorCost) : 0;
        const customerPriceCost = value.customerPrice ? Number(value.customerPrice) : 0;

        const profit = customerPriceCost - vendorPartsCost - technicianPay
        const grossMargin = customerPriceCost ? profit / customerPriceCost : 0;
        const grossMarginPercent = grossMargin * 100;
        const isLowMargin = grossMarginPercent < 30;
        return (
          <Fragment>
            <TableRow className={classes.serviceRowBottomBorders}>
              <TableCell>
                {ejiType === "INVOICE" ? (
                  <div>{value.inEstimate ? "Y" : "N"}</div>
                ) : (
                  <CheckBoxField
                    name={`services[${serviceIdx}].inEstimate`}
                    postOnChange={(checked) => {
                      let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                      servicesCopy[serviceIdx].inEstimate = checked;
                      calculatePossibleEJIPriceInfo({
                        variables: {
                          calculatePossibleEJIPriceInfoInput: {
                            pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                            services: servicesToPossibleEjiServiceInput(servicesCopy),
                            discounts: discountsToEjiDiscountInput(values.discounts),
                            promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                            marketName: values.market,
                            taxable: taxable,
                            calculateAllServices: false, //always an Estimate here
                          },
                        },
                      });
                    }}
                  />
                )}
              </TableCell>
              <TableCell padding={"none"}>
                <div className="flex">
                  {/*Only show error icon if there is an error at the product level by checking for the products key*/}
                  {has("items", error) ? (
                    <ProductErrorIcon color={"primary"} />
                  ) : (
                    isLowMargin && <LowMarginIcon color={"primary"} />
                  )}
                </div>
              </TableCell>
              <TableCell>
                <DebouncedTextField name={`services[${serviceIdx}].name`} required />
              </TableCell>
              {customerCell}
              <TableCell>
                <Field name={`services[${serviceIdx}].technicianPay`}>
                  {({ field: { value, name }, meta: { error }, form: { setFieldValue } }) => (
                    <DebouncedCurrencyInputV2
                      name={name}
                      label=""
                      value={value}
                      required
                      error={error}
                      onChange={(val) => setFieldValue(name, val)}
                    />
                  )}
                </Field>
              </TableCell>
              <TableCell>
                <div className="px-4">
                  <Field name={`services`}>
                    {({ form: { values } }) => (
                      <IconButton
                        onClick={() => {
                          const serviceRemoved = values.services.filter((_, i) => serviceIdx !== i);
                          calculatePossibleEJIPriceInfo({
                            variables: {
                              calculatePossibleEJIPriceInfoInput: {
                                pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                                services: servicesToPossibleEjiServiceInput(serviceRemoved),
                                discounts: discountsToEjiDiscountInput(values.discounts),
                                promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                                marketName: values.market,
                                taxable: taxable,
                                calculateAllServices: ejiType === "INVOICE",
                              },
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Field>
                </div>
              </TableCell>
              <TableCell>
                <div className="px-4">
                  <IconButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
                colSpan={7}
              >
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <ProductsExpansionSection
                    taxable={taxable}
                    serviceIdx={serviceIdx}
                    partsStores={partsStores}
                    ejiType={ejiType}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                    parts={parts}
                    serviceList={serviceList}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      }}
    </Field>
  );
};

export default ServiceRow;
